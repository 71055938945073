<template>
  <AppShellsNavbar>
    <template #prepend>
      <v-list-item
        active-class="navbar__active-item pt-2 dark-color"
        class="px-4 ml-4"
        exact
        height="70"
        to="/"
      >
        <v-list-item-title class="text-paragraph-mid font-weight-bold text-body-1">
          {{ $t('accounts') }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template #append>
      <div class="d-flex align-center justify-center pl-8 pr-4">
        <Avatar />
      </div>
    </template>
  </AppShellsNavbar>
  <v-main style="--v-layout-left: 0px; --v-layout-right: 0px; --v-layout-top: 70px; --v-layout-bottom: 0px;">
    <v-container class="layout__container">
      <slot />
    </v-container>
  </v-main>
  <CommonNotification />
</template>

<script lang="ts" setup>
useCurrentUserDataQuery();
</script>

<style lang="scss" scoped>
.navbar {
  &__active-item {
    border-bottom: 4px solid rgb(var(--v-theme-primary));
  }
}
.layout {
  &__container {
    max-width: 1440px;
    padding: 0 2rem;
  }
}

:deep(.v-list-item--active > .v-list-item__overlay) {
  opacity: 0;
}
</style>
